<template>
  <div class="Slides">
    <div class="admin-content">
      <adminSidebar />
      <div class="admin-body">
        <AdminLogged :brand="'Slides'" />
        <div class="table">
          <br />
          <div class="table-title">
            <span></span>
            <button class="add-new" @click="addModal = true">
              <i class="fa fa-plus"></i> Add
            </button>
          </div>
          <table class="pads">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Destination</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td><img :src="$uploads(item.image)" alt="" /></td>
                <td>{{ item.title }}</td>
                <td>{{ item.attraction.name }}</td>
                <td>
                  <button class="edit" @click="startUpdate(item)">Edit</button>
                  <button class="delete" @click="startDelete(item)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
        <Model v-if="addModal == true">
          <div class="model-header">
            <h4>Add Slides</h4>
            <button class="close" @click="addModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Title:</label>
                  <a-input
                    type="text"
                    placeholder="Caption"
                    v-model:value="newItem.title"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Destination:</label>
                  <a-select v-model:value="newItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose Destination --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in destinations"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Caption:</label>
                  <a-textarea
                    type="text"
                    placeholder="Caption"
                    v-model:value="newItem.caption"
                    rows="5"
                  ></a-textarea>
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Trip Image">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        newItem.image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="addItems()">Submit</button>
            <button class="close" @click="addModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateModal == true">
          <div class="model-header">
            <h4>Edit Slides</h4>
            <button class="close" @click="updateModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Title:</label>
                  <a-input
                    type="text"
                    placeholder="Caption"
                    v-model:value="selectedItem.title"
                  />
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Destination:</label>
                  <a-select v-model:value="selectedItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose Destination --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in destinations"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Name">Caption:</label>
                  <a-textarea
                    type="text"
                    placeholder="Caption"
                    v-model:value="selectedItem.caption"
                    rows="5"
                  ></a-textarea>
                </div>
              </div>
              <div class="input-group">
                <div class="form-group">
                  <label for="Trip Image">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        selectedItem.image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="selectedItem.image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateItems()">
              Update
            </button>
            <button class="close" @click="updateModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="deleteModal == true">
          <div class="model-header">
            <h4>Delete Slides</h4>
            <button class="close" @click="deleteModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete
              <strong>{{ selectedItem.name }}</strong
              >?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteItems()">Yes</button>
            <button class="close" @click="deleteModal = false">Cancel</button>
          </div>
        </Model>
      </div>
    </div>
  </div>
</template>

<script>
import Model from "./components/Model.vue";
export default {
  name: "Slides",
  components: {
    Model,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: "#015b0a",
      addModal: false,
      updateModal: false,
      deleteModal: false,
      newItem: {},
      selectedItem: {},
      items: [],
      token: null,
      destinations: [],
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getDestinations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ATTRACTION", { token: this.$loggedAdminToken() })
        .then((response) => {
          this.destinations = response.data.data;
          this.$stopLoader();
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SLIDE", {
          token: this.$loggedAdminToken(),
          paginate: this.itemsPerpage,
          page: this.currentPage,
        })
        .then((response) => {
          this.items = response.data.data;
          this.allItemsCount = response.data.total;
          this.pagesNumber = Math.ceil(this.allItemsCount / this.itemsPerpage);
          this.$stopLoader();
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
    },
    addItems() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_SLIDE", this.newItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.addModal = false;
            this.newItem.title = "";
            this.newItem.date = "";
            this.newItem.image = "";
            this.newItem.images = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startUpdate(item) {
      this.selectedItem = item;
      this.updateModal = true;
    },
    updateItems() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_SLIDE", this.selectedItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.updateModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startDelete(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },
    deleteItems() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_SLIDE", this.selectedItem.id)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
  },
  mounted() {
    this.getItems();
    if (this.$loggedAdmin()) {
      this.token = this.$loggedAdminToken();
    } else if (this.$loggedClient()) {
      this.token = this.$loggedClientToken();
    } else {
      this.token = null;
    }
    this.getDestinations();
  },
};
</script>

<style lang="scss" scoped></style>
