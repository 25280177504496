<template>
  <div class="About">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              About Us
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>About</strong>
            </p>
          </div>
        </div>
      </div>

      <div
        class="container for-with"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="whyus">
          <div class="left" data-aos="fade-up" data-aos-duration="1000">
            <div class="desc-tabs">
              <div
                class="tab tab-1"
                @click="activetab = 1"
                :class="{ active: activetab == 1 }"
              >
                <div class="tab-header">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g clip-path="url(#clip0_922_21)">
                      <path
                        d="M15.0504 10.4663C15.4633 10.4663 15.7977 10.1317 15.7977 9.71899C15.7977 9.30631 15.4633 8.97168 15.0504 8.97168C11.7488 8.97168 9.07227 11.6482 9.07227 14.9501C9.07227 18.2517 11.7488 20.9283 15.0504 20.9283C18.3523 20.9283 21.0288 18.2517 21.0288 14.9501C21.0288 14.5372 20.6942 14.2028 20.2815 14.2028C19.8689 14.2028 19.5342 14.5372 19.5342 14.9501C19.5342 17.4263 17.5269 19.4339 15.0504 19.4339C12.5742 19.4339 10.5666 17.4263 10.5666 14.9501C10.5666 12.4736 12.5742 10.4663 15.0504 10.4663Z"
                      ></path>
                      <path
                        d="M29.3085 10.5114C29.1787 10.1193 28.7558 9.90672 28.3634 10.0365C27.9803 10.1632 27.7669 10.5711 27.8812 10.9581C30.1159 18.0748 26.1586 25.6555 19.042 27.8903C11.9256 30.1251 4.34482 26.1678 2.11006 19.0512C-0.124991 11.9346 3.8326 4.35381 10.949 2.11899C13.5832 1.29181 16.4077 1.29181 19.042 2.11899C19.4375 2.23822 19.8547 2.0144 19.974 1.61889C20.0915 1.2293 19.8761 0.817563 19.489 0.691704C11.5869 -1.78757 3.1709 2.60859 0.691687 10.5109C-1.78759 18.4131 2.60875 26.829 10.5109 29.3082C18.4133 31.7875 26.829 27.3914 29.3082 19.489C30.2251 16.5667 30.2252 13.4338 29.3085 10.5114H29.3085Z"
                      ></path>
                      <path
                        d="M16.473 5.33889C16.4769 4.91338 16.1501 4.55772 15.7257 4.5259C15.5026 4.50691 15.2792 4.48793 15.05 4.48793C9.27193 4.48793 4.58789 9.17197 4.58789 14.9501C4.58789 20.728 9.27193 25.4121 15.05 25.4121C20.828 25.4121 25.512 20.728 25.512 14.9501C25.512 14.6798 25.4955 14.4097 25.4624 14.1415C25.4026 13.7313 25.0218 13.4473 24.6116 13.507C24.2014 13.5665 23.9174 13.9477 23.9769 14.3578C23.9792 14.3733 23.982 14.3886 23.9851 14.4042C24.0014 14.5853 24.0174 14.7647 24.0174 14.9497C24.0176 19.9024 20.0029 23.9172 15.0503 23.9177C10.0975 23.9179 6.08268 19.9028 6.0825 14.9503C6.08227 9.99779 10.0968 5.98272 15.0496 5.98254H15.0498C15.2353 5.98254 15.4148 5.99854 15.5955 6.01453L15.7355 6.02625C16.1261 6.04295 16.4561 5.7399 16.4726 5.34943C16.4728 5.34574 16.4728 5.34234 16.473 5.33889Z"
                      ></path>
                      <path
                        d="M19.5332 6.72978V9.40957L14.5211 14.4216C14.2242 14.7084 14.216 15.1815 14.5028 15.4784C14.7896 15.7752 15.2628 15.7835 15.5595 15.4967C15.5657 15.4907 15.5719 15.4846 15.5779 15.4784L20.59 10.4663H23.2696C23.4679 10.4663 23.6578 10.3876 23.7982 10.2473L28.2818 5.76345C28.5735 5.47166 28.5735 4.99857 28.2818 4.70672C28.2124 4.63734 28.13 4.58232 28.0394 4.54478C27.9487 4.50724 27.8516 4.48792 27.7535 4.48793H25.5116V2.24601C25.5116 1.83334 25.177 1.49871 24.764 1.49894C24.566 1.49899 24.3761 1.57769 24.236 1.71773L19.7522 6.20156C19.6828 6.27088 19.6277 6.35322 19.5901 6.44386C19.5525 6.5345 19.5332 6.63166 19.5332 6.72978ZM21.0278 7.03927L24.0169 4.05006V5.23523C24.0169 5.64791 24.3516 5.98254 24.7643 5.98254H25.9494L22.9602 8.9717H21.0278V7.03927Z"
                      ></path>
                    </g>
                  </svg>
                  <label for="Corporate Travel">Mission & Vision</label>
                </div>
              </div>
              <!-- qualities -->
              <div
                class="tab tab-2"
                @click="activetab = 2"
                :class="{ active: activetab == 2 }"
              >
                <div class="tab-header">
                  <i class="fa-regular fa-circle-question"></i>
                  <label for="Corporate Travel">Why&nbsp;Us</label>
                </div>
              </div>
            </div>
            <p
              class="desc"
              data-aos="fade-up"
              data-aos-duration="1000"
              v-if="activetab == 1"
            >
              Travel undertaken for corporate travel is an investment, which
              companies quantify in their cost of business. The needs of
              business travelers involves greater uncertainty and urgency as
              compared to leisure travelers, as such, a large measure of
              flexibility, speed and accuracy is required to ensure that their
              requests are met in an appropriate manner.
            </p>
            <div
              class="qualities"
              v-if="activetab == 2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div class="quality-item">
                <div class="icon">
                  <i class="fas fa-compass"></i>
                </div>
                <div class="text">
                  <label for="Orientation">Orientation</label>
                  <p>You, our Customer are our First Priority.</p>
                </div>
              </div>
              <div class="quality-item">
                <div class="icon">
                  <i class="fas fa-users"></i>
                </div>
                <div class="text">
                  <label for="Staff">Staff</label>
                  <p>Clear understanding of your needs.</p>
                </div>
              </div>
              <div class="quality-item">
                <div class="icon">
                  <i class="fas fa-briefcase"></i>
                </div>
                <div class="text">
                  <label for="Experience">Experience</label>
                  <p>Efficient, Expert and Reliable Service.</p>
                </div>
              </div>
              <div class="quality-item">
                <div class="icon">
                  <i class="fas fa-network-wired"></i>
                </div>
                <div class="text">
                  <label for="Network">Network</label>
                  <p>Best Value, Convenient, Optimal connections.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="images">
              <img src="@/assets/images/trips.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="testimonials">
        <div class="testimonials-content">
          <div class="header" data-aos="fade-up" data-aos-duration="1000">
            <label for="Testimonials" class="my-title">Testimonial</label>
            <hr class="myhr" />
            <p class="desc">What our clients said about us.</p>
          </div>
          <div class="testimonials-body">
            <Carousel
              :wrapAround="true"
              :itemsToShow="2"
              :itemsToScroll="1"
              :transition="1000"
              :autoplay="3000"
              class="test-text"
            >
              <CouSlide class="text-item">
                <p>
                  This Rwandan journey was a big blessing.
                  <strong>Thanks Sumaya and team</strong> and all the nice
                  jamaicans living in the Moter Land. Plus a so sweet tour
                  guide. We pray for our next step as well. Blessings all.
                </p>
                <label for="Travel in Rwanda">
                  - Des and Debbie (Jamaican Diaspora)</label
                >
              </CouSlide>
              <CouSlide class="text-item">
                <p>
                  Our tour of Akagera was a wonderful experience, and a large
                  element of that was owing to the arrangements provided by
                  <strong>Maken Africa Safaris</strong>. The logistics went like
                  clockwork and we appreciated the welcoming bowl of fruit in
                  our hotel rooms.
                </p>
                <label for="Travel in Rwanda"> - Katrina jihad (US)</label>
              </CouSlide>
              <CouSlide class="text-item">
                <p>
                  I would like to thank the
                  <strong>Maken Africa Safaris</strong> for the excelent service
                  provided. The reservation process was excellent and flexible.
                  The tour to Nyungwe National Park was perfect.
                </p>
                <label for="visit Rwanda"> - Alidah Muhammed (US)</label>
              </CouSlide>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      activetab: 1,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
  },
  mounted() {
    let vm = this;
  },
};
</script>

<style lang="scss">
$secondary_color: #ef6203;
$primary_color: #044914;
.About {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .about-content {
    padding: 2rem 15rem;
    @media screen and (max-width: 1700px) {
      padding: 4rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem;
      font-size: 0.75rem;
    }
    label {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 3rem;
      @media screen and (max-width: 900px) {
        line-height: 2rem;
        font-size: 1rem;
      }
    }
  }
  .about {
    padding: 4rem 15rem;
    display: grid;
    grid-template-columns: 50% 50%;
    background: #fff;
    @media screen and (max-width: 1700px) {
      padding: 4rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem;
      grid-template-columns: 100%;
    }
    .left {
      @media screen and (max-width: 900px) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px #eee solid;
      }
      .myhr {
        border-top: 3px $secondary_color solid;
        width: 70px;
        margin-bottom: 1rem;
      }
      .desc {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
    }
    .right {
      p {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
    }
  }
  .missions-widget {
    background: url("@/assets/images/pe2.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    box-shadow: inset 0 0 0 2000px #0000004d;
    padding: 2rem 15rem;
    @media screen and (max-width: 1700px) {
      padding: 4rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem;
      font-size: 0.75rem;
    }
    .mis-body {
      display: grid;
      grid-template-columns: 26.6% 26.6% 26.6%;
      grid-column-gap: 10%;
      grid-row-gap: 2rem;
      margin: 2rem 0;
      @media screen and (max-width: 1500px) {
        grid-template-columns: 30% 30% 30%;
        grid-column-gap: 3%;
        margin: 0;
      }
      @media screen and (max-width: 1100px) {
        grid-template-columns: 47.5% 47.5%;
        grid-column-gap: 3%;
      }
      @media screen and (max-width: 700px) {
        grid-template-columns: 100%;
        grid-column-gap: 0%;
      }

      .mission {
        background: #ffffffd7;
        box-shadow: 0px 0px 10px 3px #1d1d1d48;
        border-radius: 1rem;
        padding: 2rem;
        @media screen and (max-width: 700px) {
          padding: 1.5rem;
        }
      }
      .vision {
        background: #ffffffd7;
        box-shadow: 0px 0px 10px 3px #1d1d1d48;
        border-radius: 1rem;
        padding: 2rem;
        position: relative;
        left: -1rem;
      }
    }
  }
}
</style>
