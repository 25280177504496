import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store';
import EasySlider from "vue-easy-slider";
import vuescroll from "vuescroll/dist/vuescroll-native";
import "vuescroll/dist/vuescroll.css";
import "./scss/main.scss";
import Antd from "ant-design-vue";
import AOS from "aos";
import "aos/dist/aos.css";

import "./scss/ant.scss";
import "@innologica/vue-dropdown-menu";
import VueConfirmDialog from "vue3-confirm-dialog";
import VueClipboard from "vue-clipboard3";
import VueContentLoading from "vue-content-loading";
// import AskAuth from "./components/modals/AskAuth.vue";
// import Uploader from "./components/modals/upload-helper";
// import GoogleMap from "./components/modals/map-helper";
import VueSlickCarousel from "vue-slick-carousel";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueYoutube from "vue3-youtube";
// import VUpload from "./components/VUpload.vue";
// import uploadHelper from "./components/uploadHelper.vue";
// import VEditor from "./components/VEditor.vue";
import Notifications from "@kyvg/vue3-notification";
import {
  Hooper,
  Slide,
  Progress as HooperProgress,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper-vue3";
import "hooper-vue3/dist/hooper.css";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import 'vue3-carousel/dist/carousel.css'
import { Carousel as Carousel, Slide as CouSlide, Pagination, Navigation } from 'vue3-carousel'



import AppMix from "./mixin/appMix.vue";

// pages

import clientHeader from "./views/site/pages/Header.vue";
import clientFooter from "./views/site/pages/Footer.vue";
import BookPackage from "./views/site/components/BookPackage.vue";
import BookTrip from "./views/site/components/BookTrip.vue";
import Done from "./views/site/components/Done.vue";
import adminSidebar from "./views/admin/pages/sidebar.vue";
import AdminLogged from "./views/admin/components/Logged.vue";
import leftView from "./views/site/components/leftView.vue";
import OUpload from './components/OUpload.vue'

const app = createApp(App);

app.component("clientHeader", clientHeader);
app.component("clientFooter", clientFooter);
app.component("BookPackage", BookPackage);
app.component("BookTrip", BookTrip);
app.component("adminSidebar", adminSidebar);
app.component("AdminLogged", AdminLogged);
app.component("leftView", leftView);
app.component("Done", Done);
app.component("leftView", leftView);
app.component("QuillEditor", QuillEditor);
app.use(Notifications);
app.mixin(AppMix);

app.use(Antd);
AOS.init();

app.component("Hooper", Hooper);
app.component("Slide", Slide);
app.component("HooperProgress", HooperProgress);
app.component("HooperPagination", HooperPagination);
app.component("HooperNavigation", HooperNavigation);
app.component("OUpload", OUpload);

app.component("Carousel", Carousel);
app.component("CouSlide", CouSlide);
app.component("Pagination", Pagination);
app.component("Navigation", Navigation);

app.use(VueYoutube);
library.add(faPlayCircle);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("VueContentLoading", VueContentLoading);
app.component("vue-confirm-dialog", VueConfirmDialog.default);

app.use(VueAxios, axios)
app.use(store)
app.use(router)
app.mount('#app');
