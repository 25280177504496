<template>
  <div class="Partners">
    <div class="admin-content">
      <adminSidebar />
      <div class="admin-body">
        <AdminLogged :brand="'Partners'" />
        <div class="table">
          <div class="table-title">
            <label for="Partners For Ren">Partners</label>
            <button class="add-new" @click="addModal = true">
              <i class="fa fa-plus"></i> Add
            </button>
          </div>
          <table class="pads">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td><img :src="$uploads(item.image)" alt="" /></td>
                <td>{{ item.name }}</td>
                <td>{{ item.link }}</td>
                <td>
                  <button class="edit" @click="startUpdate(item)">Edit</button>
                  <button class="delete" @click="startDelete(item)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Model v-if="addModal == true">
          <div class="model-header">
            <h4>Add Partners</h4>
            <button class="close" @click="addModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="form-group">
                <label for="Name">Name:</label>
                <a-input
                  type="text"
                  placeholder="Name"
                  v-model:value="newItem.name"
                />
              </div>
              <div class="form-group">
                <label for="Link">Link:</label>
                <a-input
                  type="text"
                  id=""
                  placeholder="Link"
                  v-model:value="newItem.link"
                />
              </div>
              <div class="form-group">
                <label for="Trip Image">Image</label>
                <br />
                <o-upload
                  :multiple="false"
                  @imagesUploaded="
                    (data) => {
                      newItem.image = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="newItem.image"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="addItems()">Submit</button>
            <button class="close" @click="addModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateModal == true">
          <div class="model-header">
            <h4>Edit Partners</h4>
            <button class="close" @click="updateModal = false">X</button>
          </div>
          <div class="model-body">
            <form>
              <div class="form-group">
                <label for="name">Name:</label>
                <a-input
                  type="text"
                  placeholder="Name"
                  v-model:value="selectedItem.name"
                />
              </div>
              <div class="form-group">
                <label for="Date">Link:</label>
                <a-input
                  type="link"
                  id=""
                  placeholder="link"
                  v-model:value="selectedItem.link"
                />
              </div>
              <div class="form-group">
                <label for="Trip Image">Image</label>
                <br />
                <o-upload
                  :multiple="false"
                  @imagesUploaded="
                    (data) => {
                      selectedItem.image = data;
                    }
                  "
                  @imagesUploadedFailed="
                    (data) => {
                      console.log('failed');
                    }
                  "
                  :value="selectedItem.image"
                  @startLoader="() => $startLoader()"
                  @stopLoader="() => $stopLoader()"
                />
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateItems()">
              Update
            </button>
            <button class="close" @click="updateModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="deleteModal == true">
          <div class="model-header">
            <h4>Delete Partners</h4>
            <button class="close" @click="deleteModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete
              <strong>{{ selectedItem.name }}</strong
              >?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteItems()">Yes</button>
            <button class="close" @click="deleteModal = false">Cancel</button>
          </div>
        </Model>
      </div>
    </div>
  </div>
</template>

<script>
import Model from "./components/Model.vue";
export default {
  name: "Partners",
  components: {
    Model,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: "#015b0a",
      addModal: false,
      updateModal: false,
      deleteModal: false,
      newItem: {},
      selectedItem: {},
      items: [],
      token: null,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PARTNER", { token: this.$loggedAdminToken() })
        .then((response) => {
          this.items = response.data;
          this.$stopLoader();
        });
    },
    addItems() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_PARTNER", this.newItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.addModal = false;
            this.newItem.title = "";
            this.newItem.date = "";
            this.newItem.image = "";
            this.newItem.images = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startUpdate(item) {
      this.selectedItem = item;
      this.updateModal = true;
    },
    updateItems() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_PARTNER", this.selectedItem)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.updateModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
    startDelete(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },
    deleteItems() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_PARTNER", this.selectedItem.id)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteModal = false;
            this.selectedItem = "";
            this.getItems();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
      this.$stopLoader();
    },
  },
  mounted() {
    this.getItems();
    if (this.$loggedAdmin()) {
      this.token = this.$loggedAdminToken();
    } else if (this.$loggedClient()) {
      this.token = this.$loggedClientToken();
    } else {
      this.token = null;
    }
  },
};
</script>

<style lang="scss" scoped></style>
