<template>
  <div id="app">
    <router-view />
    <notifications group="status" position="bottom right" :duration="5000" />

    <vue-confirm-dialog />
    <div class="app-loader" v-if="isLoading">
      <div class="loading-panel">
        <a-spin :spinning="true"></a-spin>
        <span class="text">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {
    let vm = this;
  },
  methods: {},
  watch: {},
  computed: {
    ...mapState(["isLoading"]),
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "@/scss/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap");
* {
  font-family: "Lato", sans-serif;
  // font-family: "Ma Shan Zheng", cursive;
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
button,
a {
  cursor: pointer !important;
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.group-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
.group-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-7 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-8 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-9 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.group-10 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
}
.help {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  padding: 1.1rem;
  background-color: $primary_color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: 0px 0px 10px 2px #0000004f;
  transition: all 0.4s ease-in-out;
  z-index: 99999;
  &:hover,
  &:focus {
    background: $primary_color;
  }
  i {
    color: white;
    font-size: 2rem;
  }
}
.app-loader {
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0000007a;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);

  .loading-panel {
    width: 174px;
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 10px 13px;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    .text {
      font-size: 19px;
      margin-left: 19px;
      color: $primary_color;
    }
    i {
      background: $primary_color;
    }
  }
}
.my-title {
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2;
  @media screen and (max-width: 900px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
    line-height: 1.25;
    padding-bottom: 0.5rem;
  }
}
.intro-header {
  text-shadow: 0 4px 2px #0000007e;
  color: white;
  font-size: 4rem;
  line-height: 1.25em;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 2.25rem;
    line-height: 1.25;
    padding-bottom: 0.5rem;
  }
}
.intro-desc {
  color: #fff;
  font-weight: 400;
  font-size: 1.25rem;
  text-shadow: 0 4px 2px #0000007e;
  text-align: center;
  @media screen and (max-width: 900px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 0.9rem;
  }
}
.myhr {
  border: none;
  border-top: 1px $primary_color solid;
  width: 70px;
  margin-bottom: 1rem;
}
.mybtn {
  padding: 0.8rem 1.5rem;
  border: none;
  color: white;
  background: $primary_color;
  border-radius: 3px;
  transition: all 0.7s ease-in-out;
  display: inline-block;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: inset 7rem 5rem 0 0.4rem $darkprimary_color;
    color: white;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.6rem 1.2rem;
  }
}
.mybtn-o {
  padding: 0.8rem 1.5rem;
  border: none;
  color: $primary_color;
  border-radius: 3px;
  transition: all 0.7s ease-in-out;
  display: inline-block;
  border: 2px $primary_color solid;
  background-color: #fff;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: inset 20em 3rem 0 0 $darkprimary_color;
    color: white;
  }
  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.6rem 1.2rem;
  }
}
.txt-shad {
  text-shadow: 0 4px 2px #0000007e;
}
.txt-shad-sm {
  text-shadow: 0 2px 2px #0000007e;
}
.d-flex {
  display: flex;
}
.jc-center {
  display: flex;
  justify-content: center;
}
.ai-center {
  display: flex;
  align-items: center;
}

.intro {
  background: url("/public/img/kigali.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  .intro-content {
    padding: 3rem 0;
    padding-top: 10rem;
    width: 100%;
    height: 100%;
    background: #000000a4;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1500px) {
      padding-top: 5rem;
    }
    @media screen and (max-width: 900px) {
      padding-top: 5rem;
    }
    .intro-body {
      width: 100%;
      padding: 0 15rem;
      @media screen and (max-width: 1700px) {
        padding: 4rem 10rem;
      }
      @media screen and (max-width: 1300px) {
        padding: 3rem 5rem;
      }
      @media screen and (max-width: 900px) {
        padding: 1rem;
      }
    }
    p {
      font-size: 0.95rem;
      font-weight: 300;
    }
    strong {
      color: $secondary_color;
    }
  }
}

.admin-content {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.admin-body {
  width: calc(100vw - 200px);
}
.page-brand {
  font-size: 1rem;
  color: #2a2a2a;
  padding: 1rem;
  border-bottom: 1px #eee solid;
  width: 100%;
}

.table {
  padding: 0 2rem;
  overflow-x: auto;
  max-height: 90vh;
  .table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 1rem;
    }
    label {
      font-size: 1rem;
      font-weight: 500;
      padding: 1rem 0;
      border-bottom: 1px #f0f0f0 solid;
      line-height: 2;
    }
    .add-new {
      padding: 5px 1rem;
      font-size: 0.9rem;
      color: white;
      font-weight: 500;
      border-radius: 3000px;
      border: none;
      outline: none;
      cursor: pointer;
      background: $secondary_color;
      box-shadow: 0px 0px 10px 2px #015b0a32;
    }
  }
  table {
    width: 100%;
    tr {
      border-bottom: 1px #f0f0f0 solid;
      th {
        line-height: 2;
        padding: 0 1rem;
        text-transform: capitalize;
        font-size: 0.9rem;
        font-weight: 500;
      }
      td {
        font-weight: 300;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0 1rem;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          padding: 3px;
          &.alt {
            width: auto;
            border-radius: 0;
          }
        }
        button,
        .button {
          padding: 5px 1rem;
          font-size: 0.7rem;
          color: white;
          font-weight: 500;
          border-radius: 3000px;
          border: none;
          outline: none;
          cursor: pointer;
          margin-right: 0.25rem;
          &.edit {
            background: #02b4eb;
            box-shadow: 0px 0px 10px 2px #02b5eb4d;
          }
          &.videos {
            background: #01cc60;
            box-shadow: 0px 0px 10px 2px #01cc6055;
          }
          &.delete {
            background: #b80303;
            box-shadow: 0px 0px 10px 2px #b8030363;
          }
          a {
            color: white;
          }
        }
      }
      .noData {
        text-align: center;
        padding: 3rem;
        color: #f54d4d;
      }
    }
    &.pads {
      th,
      td {
        padding: 10px 1rem;
      }
    }
  }
}
.uploader-btn {
  background: $secondary_color;
  color: white;
  cursor: pointer;
  padding: 0.4rem 1rem;
  border-radius: 3px;
}

.ant-upload-list-item {
  position: relative !important;
  display: inline-block !important;
  float: none !important;
}
.disabled {
  cursor: no-drop !important;
  // background: #e9e9e9 !important;
  color: #141414 !important;
}
td {
  &.primary_color {
    color: primary_color !important;
  }
}
.flex-c {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0rem 15rem;
  @media screen and (max-width: 1700px) {
    padding: 0rem 6rem;
  }
  @media screen and (max-width: 1300px) {
    padding: 0rem 5rem;
  }
  @media screen and (max-width: 900px) {
    padding: 0rem 3rem;
  }
  @media screen and (max-width: 600px) {
    padding: 0rem 1rem;
  }
}

.tag-header {
  display: flex;
  justify-content: center;
  align-items: center;
  &.left {
    justify-content: flex-start;
  }
  p {
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    font-family: "Rouge Script", cursive;
    background: url("@/assets/images/bgog.svg") no-repeat;
    background-size: contain;
    padding: 1rem 2.5rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 132px;
  }
}
.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333333;
    line-height: 1;
  }
  .desc {
    color: #555555;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
    width: 50%;
    @media screen and (max-width: 1300px) {
      width: 70%;
    }
    @media screen and (max-width: 900px) {
      width: 90%;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    border: none;
    background: none;
    label {
      padding: 5px 15px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.3s;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.active {
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
}

.for-with {
  background: #edf2dea2;
  .whyus {
    display: grid;
    grid-template-columns: 50% 50%;
    background: url("@/assets/images/bgs-04.png") no-repeat;
    background-size: contain;
    padding-top: 8rem;
    padding-bottom: 8rem;
    @media screen and (max-width: 900px) {
      grid-template-columns: 100%;
    }
    .left {
      padding-right: 3rem;
      @media screen and (max-width: 900px) {
        padding-right: 0rem;
      }
      .my-title {
        line-height: 1.5;
      }
      .desc-tabs {
        display: flex;
        gap: 1rem;
        margin: 2rem 0;
        .tab {
          .tab-header {
            display: flex;
            align-items: center;
            column-gap: 0.3rem;
            svg {
              fill: black;
              height: 25px;
            }
            i {
              font-size: 1.5rem;
            }
            label {
              font-size: 1.1rem;
              font-weight: 600;
              color: black;
              cursor: pointer;
            }
          }
          &.active,
          &:hover {
            label {
              color: $primary_color;
            }
            i {
              color: $primary_color;
            }
            svg {
              fill: $primary_color;
            }
          }
        }
      }
      .desc {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
      .qualities {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 1rem;
        @media screen and (max-width: 1100px) {
          grid-template-columns: 48% 48%;
        }
        @media screen and (max-width: 600px) {
          grid-template-columns: 100%;
        }
        .quality-item {
          background: #fff;
          display: grid;
          grid-template-columns: 1fr 3fr;
          align-items: center;
          border-radius: 10px;
          padding: 1rem;
          gap: 1rem;
          &:nth-child(1),
          &:nth-child(4) {
            background: rgba(0, 128, 0, 0.219);
          }
          &:nth-child(2),
          &:nth-child(3) {
            background: rgba(226, 191, 139, 0.219);
          }
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              padding: 1rem;
              border-radius: 10px;
              font-size: 2rem;
              color: $primary_color;
              background: #f5f5f5;
            }
          }
          .text {
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            justify-self: center;
            label {
              color: $darkprimary_color;
              font-weight: 600;
              font-size: 1.1rem;
            }
            p {
              color: #525252;
              font-weight: 400;
              font-size: 0.9rem;
              margin-bottom: 0;
              @media screen and (max-width: 600px) {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("@/assets/images/bgs-05.png") no-repeat;
      background-size: cover;
      .images {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 1rem;
        @media screen and (max-width: 900px) {
          grid-template-columns: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 10px;
        }
      }
    }
  }
}

.testimonials {
  position: relative;
  background: url("@/assets/images/gor1.jpg");
  background-size: cover;
  padding-bottom: 10rem;
  .testimonials-content {
    width: 80%;
    background-color: white;
    padding: 4rem 15rem;
    background: url("@/assets/images/bgsw-02.png") no-repeat;
    background-size: cover;
    @media screen and (max-width: 1700px) {
      padding: 4rem 10rem;
    }
    @media screen and (max-width: 1300px) {
      padding: 3rem 5rem;
      width: 90%;
    }
    @media screen and (max-width: 900px) {
      padding: 2rem;
      width: 95%;
    }
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;
    }
    .testimonials-body {
      .test-text {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 1rem;
        @media screen and (max-width: 550px) {
          grid-template-columns: 100%;
          &:nth-child(0) {
            border-bottom: 1px #eee solid;
          }
        }
        .text-item {
          padding-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          row-gap: 1rem;
          flex-direction: column;
          @media screen and (max-width: 550px) {
            padding-right: 0rem;
          }
          p {
            font-size: 0.9rem;
            font-weight: 500;
            color: #1d1d1d;
            text-align: center;
            background: #fff;
            padding: 1rem;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 3px #4d4d4d0e;
            margin: 1rem;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid #fff;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.8rem;
            }
          }
          label {
            color: $secondary_color;
            text-align: center;
            font-weight: 600;
            font-style: italic;
            @media screen and (max-width: 900px) {
              font-size: 0.9rem;
            }
          }
        }
      }
      .partners {
        margin-top: 4rem;
        .partners-body {
          align-items: center !important;
          .partners-item {
            padding: 1.5rem;
            img {
              width: 80%;
            }
          }
        }
      }
    }
  }
}

.packages {
  position: relative;
  background-size: cover;
  // padding: 8rem 1rem;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 600px) {
      align-items: flex-start;
    }
    .desc {
      color: #333333;
      font-weight: 400;
      font-size: 1.1rem;
      @media screen and (max-width: 1200px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 900px) {
        font-size: 0.8rem;
      }
    }
  }
  .filter-btns {
    display: flex;
    gap: 1rem;
    background: white;
    box-shadow: 0px 0px 10px 0px #00000033;
    padding: 1rem 3rem;
    border-radius: 10px;
    margin-top: 2rem;
    button {
      padding: 0.5rem 2rem;
      font-size: 1rem;
      font-weight: 500;
      color: $primary_color;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      background: transparent;
      border: 1px solid $primary_color;
      &:hover,
      &.active {
        background: $primary_color;
        color: white;
      }
    }
  }
  .packages-body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 2%;
    gap: 1rem;
    margin: 2rem 0;
    flex-wrap: wrap;
    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }
    .package-item {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 3px #4d4d4d11;
      // overflow: hidden;
      transition: all 0.4s ease-in-out;
      &:hover,
      &:focus {
        z-index: 1;
      }
      .content {
        display: flex;
        flex-direction: column;
        position: relative;
        .img-section {
          position: relative;
          height: 250px;
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          @media screen and (max-width: 1500px) {
            height: 200px;
          }
          @media screen and (max-width: 1300px) {
            height: 200px;
          }
          @media screen and (max-width: 1100px) {
            height: 250px;
          }
          @media screen and (max-width: 700px) {
            height: 200px;
          }
          img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
            object-fit: cover;
            &:hover {
              transform: scale(1.3, 1.3);
              transition: all 0.4s ease-in-out;
            }
          }
          .specs {
            position: absolute;
            top: 0;
            left: -3px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 0 0 10px 10px;
            padding-top: 1rem;
            .region {
              position: absolute;
              background: $primary_color;
              display: flex;
              align-items: center;
              column-gap: 0.45rem;
              top: 0;
              left: 0;
              padding: 0.5rem 0.95rem;
              border-radius: 5px;
              line-height: 1;
              i {
                font-size: 0.8rem;
              }
            }
            .days {
              position: absolute;
              top: 30px;
              left: 0;
              background: #fff;
              padding: 0.25rem 0.75rem;
              border-radius: 5px;
              color: #000;
              font-size: 1rem;
            }
          }
        }
        .text-section {
          padding: 0 1rem;
          padding-top: 2rem;
          @media screen and (max-width: 1300px) {
            padding: 0 0.75rem;
            padding-top: 0.75rem;
          }
          .package-title {
            color: #1d1d1d;
            font-weight: 500;
            font-size: 1.2rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1rem;
            }
          }
        }
        hr {
          align-self: center;
          width: 90%;
          border: none;
          border-top: 1px #eee solid;
          margin: 1rem 0;
          @media screen and (max-width: 1500px) {
            margin: 0.5rem 0;
          }
        }
        .content-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1rem;
          padding-bottom: 10px;
          .money {
            color: $primary_color;
            font-size: 1.2rem;
            font-weight: 500;
            @media screen and (max-width: 1500px) {
              font-size: 1rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1rem;
            }
            span {
              color: #000000;
              font-size: 0.75rem;
              font-weight: 400;
              @media screen and (max-width: 1500px) {
                font-size: 0.7rem;
              }
            }
            p {
              font-size: 1.6rem;
              font-weight: 600;
              margin-bottom: 0;
              @media screen and (max-width: 1500px) {
                font-size: 1.2rem;
              }
              @media screen and (max-width: 1300px) {
                font-size: 1rem;
              }
            }
          }
          .book {
            .book-btn {
              color: #fff;
              font-size: 0.8rem;
              font-weight: 600;
              padding: 0.75rem 1.5rem;
              background: $primary_color;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              transition: all 0.4s ease-in-out;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              i {
                font-size: 1rem;
              }
              @media screen and (max-width: 1500px) {
                font-size: 0.7rem;
                padding: 0.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
  .packages-footer {
    padding: 2rem 0;
  }
}

.model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px #f0f0f0 solid;
  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .close {
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    i {
      font-size: 1.5rem;
      color: $primary_color;
    }
  }
}
.model-body {
  padding: 1rem 1rem;
  .form-group {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 1rem;
    input,
    textarea,
    select {
      padding: 0.45rem 0.75em;
      border: 1px #e2e2e2 solid;
      outline: none;
      border-radius: 3px;
      background: #eeeeee1e;
      color: #000;
      font-size: 0.9rem;
    }
    input[type="submit"] {
      cursor: pointer;
      border: none;
      background: $primary_color;
      color: white;
      font-weight: 500;
    }
    label {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
  .input-group {
    margin-bottom: 1rem;
  }
}
.model-footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  button {
    padding: 0.5rem 1rem;
    border: none;
    background: $primary_color;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:hover {
      background: $secondary_color;
    }
    &.close,
    &.cancel {
      background: #1d0101;
      &:hover {
        background: #1d0101;
      }
    }
  }
}
.table-buttons {
  display: flex;
  gap: 0;
  border-radius: 3px;
  overflow: hidden;
  width: fit-content;
  box-shadow: 0px 0px 10px 2px #0000001a;
  button,
  a {
    margin: 0;
    margin-right: 0 !important;
    border-radius: 0 !important;
    font-size: 0.9rem !important;
    box-shadow: none !important;
    border-right: 2px #c0c0c067 solid !important;
    width: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      border-right: none !important;
    }
  }
}
.no-data {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-style: oblique;
}

.filter-packages {
  background: #fff;
  // box-shadow: 0px 0px 10px 0px #0000001a;
  border-right: 1px #0000001a solid;
  border-left: 1px #0000001a solid;
  width: 100%;
  padding: 1rem;
  position: sticky;
  .filter-header {
    label {
      font-size: 1.5rem;
      font-weight: 600;
      color: #000;
    }
  }
  .filter-body {
    .filter-item {
      margin-top: 2rem;
      border-bottom: 1px #0000001a solid;
      padding-bottom: 1rem;
      &:last-child {
        border-bottom: none;
      }
      label {
        font-size: 1.2rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 1rem;
        display: block;
        @media screen and (max-width: 1500px) {
          font-size: 1rem;
        }
      }
      input[type="text"] {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #c2c2c2;
        border-radius: 5px;
        margin-top: 0.5rem;
      }
      .checkboxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        @media screen and (max-width: 1500px) {
          grid-gap: 0.3rem;
        }
        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .checkbox {
          display: flex;
          align-items: center;
          input[type="checkbox"] {
            margin-right: 0.5rem;
          }
          label {
            font-size: 1rem;
            color: #000;
            margin-bottom: 0rem;
            display: inline-block;
            @media screen and (max-width: 1500px) {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}

.pac {
  display: grid;
  grid-template-columns: 70% 30%;
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr 300px;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 100%;
  }
  .left-section {
    padding-top: 0rem;
    .packages {
      padding: 0 !important;
    }
  }
  .right-section {
    padding: 2rem 0;
    padding-left: 2rem;
    @media screen and (max-width: 900px) {
      padding-left: 0rem;
    }
  }
}
</style>
