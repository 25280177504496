<template>
  <div class="Packages">
    <div class="admin-content">
      <adminSidebar />
      <div class="admin-body">
        <AdminLogged :brand="'Packages'" />
        <div class="table">
          <br />
          <div class="table-title">
            <label for="Packages"></label>
            <button class="add-new" @click="addModal = true">
              <i class="fa fa-plus"></i> Add Package
            </button>
          </div>
          <table class="pads">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Main Attraction</th>
                <th>Destination</th>
                <th>duration</th>
                <th>max&nbsp;people</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <img
                    :src="$uploads(item.image)"
                    :alt="item.name"
                    class="thumbnail"
                  />
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.attraction_name }}</td>
                <td>{{ item.attraction.name }}</td>
                <td>{{ item.duration }}</td>
                <td>{{ item.max_people }}</td>
                <td>
                  <div class="table-buttons">
                    <router-link
                      :to="'/admin/packages/orders/' + item.id"
                      class="button videos"
                      >Orders</router-link
                    >
                    <button class="edit" @click="startUpdate(item)">
                      Edit
                    </button>
                    <button class="delete" @click="startDelete(item)">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
        <Model v-if="addModal == true" :size="'lg'">
          <div class="model-header">
            <h4>Add Package</h4>
            <button class="close" @click="addModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="Name">Destination:</label>
                  <a-select v-model:value="newItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose Destination --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in attractions"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="Title">Title:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="A 3 Days Kigali City Tour"
                    v-model:value="newItem.title"
                  />
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="Name">Main attraction:</label>
                  <a-input
                    v-model:value="newItem.attraction_name"
                    placeholder="Main attraction"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Duration:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="5 Days / 6 Nights"
                    v-model:value="newItem.duration"
                  />
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="min_age">Category:</label>

                  <a-tree-select
                    v-model:value="newItem.category"
                    style="width: 100%"
                    :tree-data="treeData"
                    tree-checkable
                    allow-clear
                    placeholder="Please select"
                    tree-node-filter-prop="label"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Max&nbsp;people:</label>
                  <a-input
                    type="text"
                    name="max_people"
                    id=""
                    placeholder="max_people"
                    v-model:value="newItem.max_people"
                  />
                </div>
                <div class="form-group">
                  <label for="">Min Price(USD):</label>
                  <a-input-group compact>
                    <a-input
                      v-model:value="newItem.price"
                      placeholder="Price"
                    ></a-input>
                  </a-input-group>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="" class="mt-2">Inclusives</label
                  ><a-select
                    v-model:value="newItem.inclusives"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Inclusives"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
                <div class="form-group">
                  <label for="" class="mt-2">Exclusives</label>
                  <a-select
                    v-model:value="newItem.exclusives"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Exclusives"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Attraction location</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Attraction location"
                    v-model:value="newItem.location"
                  />
                </div>
                <div class="form-group">
                  <label for="email address">Popular</label>
                  <a-select
                    v-model:value="newItem.popular"
                    style="width: 100%"
                    placeholder="Popular"
                  >
                    <a-select-option :value="true">Yes</a-select-option>
                    <a-select-option :value="false">No</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="email address">Description</label
                  ><quill-editor
                    v-model:value="newItem.description"
                    theme="snow"
                    contentType="html"
                  ></quill-editor>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        newItem.image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>

                <div class="form-group">
                  <label for="email address">Other Images</label>
                  <br />
                  <o-upload
                    :multiple="true"
                    @imagesUploaded="
                      (data) => {
                        newItem.images = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.images"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
              <div class="input-group group-1 days">
                <div
                  class="form-group"
                  v-for="(day, index) in newItem.days"
                  :key="index"
                >
                  <label for="email address">Day {{ index + 1 }}</label>
                  <a-input
                    type="text"
                    id=""
                    :placeholder="'Day ' + (index + 1)"
                    v-model:value="day.title"
                  />
                  <quill-editor
                    v-model:value="day.description"
                    theme="snow"
                    contentType="html"
                  ></quill-editor>
                  <div class="remove-day">
                    <button
                      type="button"
                      class="remove-day"
                      @click="newItem.days.splice(index, 1)"
                    >
                      Remove Day {{ index + 1 }}
                    </button>
                  </div>
                </div>
                <div class="add-day">
                  <button type="button" class="add-day" @click="addNewDay()">
                    Add Day
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" type="submit" @click.prevent="addItems()">
              Submit
            </button>
            <button class="close" @click="addModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateModal == true" :size="'lg'">
          <div class="model-header">
            <h4>Edit Package</h4>
            <button class="close" @click="updateModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="Name">Destination:</label>
                  <a-select v-model:value="selectedItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose Destination --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in attractions"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="Title">Title:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="A 3 Days Kigali City Tour"
                    v-model:value="selectedItem.title"
                  />
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="Name">Main attraction:</label>
                  <a-input
                    v-model:value="selectedItem.attraction_name"
                    placeholder="Main attraction"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Duration:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="5 Days / 6 Nights"
                    v-model:value="selectedItem.duration"
                  />
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="min_age">Category:</label>

                  <a-tree-select
                    v-model:value="selectedItem.category"
                    style="width: 100%"
                    :tree-data="treeData"
                    tree-checkable
                    allow-clear
                    placeholder="Please select"
                    tree-node-filter-prop="label"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Max&nbsp;people:</label>
                  <a-input
                    type="text"
                    name="max_people"
                    id=""
                    placeholder="max_people"
                    v-model:value="selectedItem.max_people"
                  />
                </div>
                <div class="form-group">
                  <label for="">Min Price(USD):</label>
                  <a-input
                    v-model:value="selectedItem.price"
                    placeholder="Price"
                  ></a-input>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="" class="mt-2">Inclusives</label
                  ><a-select
                    v-model:value="selectedItem.inclusives"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Inclusives"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
                <div class="form-group">
                  <label for="" class="mt-2">Exclusives</label>
                  <a-select
                    v-model:value="selectedItem.exclusives"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Exclusives"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Attraction location</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Attraction location"
                    v-model:value="selectedItem.location"
                  />
                </div>
                <div class="form-group">
                  <label for="email address">Popular</label>
                  <a-select
                    v-model:value="selectedItem.popular"
                    style="width: 100%"
                    placeholder="Popular"
                  >
                    <a-select-option :value="1">Yes</a-select-option>
                    <a-select-option :value="0">No</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="email address">Description</label
                  ><quill-editor
                    v-model:content="selectedItem.description"
                    theme="snow"
                    contentType="html"
                  ></quill-editor>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        selectedItem.image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="selectedItem.image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
                <div class="form-group">
                  <label for="email address">Other Images</label>
                  <br />
                  <o-upload
                    :multiple="true"
                    @imagesUploaded="
                      (data) => {
                        selectedItem.images = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="selectedItem.images"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
              <div class="input-group group-1 days">
                <div
                  class="form-group"
                  v-for="(day, index) in selectedItem.days"
                  :key="index"
                >
                  <label for="email address">Day {{ index + 1 }}</label>
                  <a-input
                    type="text"
                    id=""
                    :placeholder="'Day ' + (index + 1)"
                    v-model:value="day.title"
                  />
                  <quill-editor
                    v-model:content="day.description"
                    theme="snow"
                    contentType="html"
                  ></quill-editor>
                  <div class="remove-day">
                    <button
                      type="button"
                      class="remove-day"
                      @click="selectedItem.days.splice(index, 1)"
                    >
                      Remove Day {{ index + 1 }}
                    </button>
                  </div>
                </div>
                <div class="add-day">
                  <button
                    type="button"
                    class="add-day"
                    @click="addNewDay('update')"
                  >
                    Add Day
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateItems()">
              Update
            </button>
            <button class="close" @click="updateModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="deleteModal == true">
          <div class="model-header">
            <h4>Delete Package</h4>
            <button class="close" @click="deleteModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete this package?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteItems()">Yes</button>
            <button class="close" @click="deleteModal = false">Cancel</button>
          </div>
        </Model>
      </div>
    </div>
  </div>
</template>

<script>
import Model from "./components/Model.vue";
export default {
  name: "Packages",
  components: {
    Model,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: "#015b0a",
      addModal: false,
      updateModal: false,
      deleteModal: false,
      newItem: {
        attraction_id: "",
        category: [],
        title: "",
        duration: "",
        min_age: "",
        max_people: "",
        price: "",
        inclusives: [],
        exclusives: [],
        reqs: [],
        image: "",
        images: "",
        location: "",
        description: "",
        days: [
          {
            title: "",
            description: "",
          },
        ],
        popular: false,
      },
      selectedItem: {},
      items: [],
      attractions: [],
      token: null,
      treeData: [
        {
          label: "Family Tour",
          value: "Family Tour",
        },
        {
          label: "Group Tour",
          value: "Group Tour",
        },
        {
          label: "Honeymoon Tour",
          value: "Honeymoon Tour",
        },
        {
          label: "Adventure Tour",
          value: "Adventure Tour",
        },
        {
          label: "Solo Tour",
          value: "Solo Tour",
        },
      ],
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PACKAGE", {
          token: this.$loggedAdminToken(),
          paginate: this.itemsPerpage,
          page: this.currentPage,
        })
        .then((response) => {
          this.items = response.data.packages.data;
          this.attractions = response.data.attractions;
          this.$stopLoader();
        });
    },
    addItems() {
      this.$startLoader();
      if (
        this.newItem.attraction_id != "" &&
        this.newItem.duration != "" &&
        this.newItem.max_people != "" &&
        this.newItem.image
      ) {
        this.$store
          .dispatch("ADD_PACKAGE", this.newItem)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.addModal = false;
              this.newItem.name = "";
              this.newItem.country = "";
              this.newItem.region = "";
              this.newItem.images = "";
              this.newItem.image = "";

              this.getItems();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
          });
        this.$stopLoader();
      } else {
        this.$stopLoader();
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startUpdate(item) {
      this.selectedItem = item;
      this.updateModal = true;
    },
    updateItems() {
      this.$startLoader();
      if (
        this.selectedItem.attraction_id != "" &&
        this.selectedItem.duration != "" &&
        this.selectedItem.min_age != "" &&
        this.selectedItem.max_people != "" &&
        this.selectedItem.image
      ) {
        this.$store
          .dispatch("UPDATE_PACKAGE", this.selectedItem)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.updateModal = false;
              this.selectedItem = "";
              this.getItems();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            this.$stopLoader();
          });
        this.$stopLoader();
      } else {
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startDelete(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },
    deleteItems() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_PACKAGE", this.selectedItem.id)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteModal = false;
            this.selectedItem = "";
            this.getItems();
            this.$stopLoader();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
            this.$stopLoader();
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
      this.$stopLoader();
    },

    addDetails(type) {
      let vm = this;
      switch (type) {
        case "price":
          this.prices.push({
            label: this.pricing.label,
            value: this.pricing.value,
          });
          this.pricing.label = "";
          this.pricing.value = "";
          break;
        case "inclu":
          this.inclusives.push({
            label: this.inclu.label,
          });
          this.inclu.label = "";
          break;
        case "exclu":
          this.exclusives.push({
            label: this.exclu.label,
          });
          this.exclu.label = "";
          break;
        case "req":
          this.reqs.push({
            label: this.req.label,
          });
          this.req.label = "";
          break;

        default:
          break;
      }
    },
    removeDetails(index, type) {
      let vm = this;
      switch (type) {
        case "price":
          this.prices.splice(index, 1);
          break;
        case "inclu":
          this.inclusives.splice(index, 1);
          break;
        case "exclu":
          this.exclusives.splice(index, 1);
          break;
        case "req":
          this.reqs.splice(index, 1);
          break;

        default:
          break;
      }
    },
    addNewDay(action) {
      if (action == "update") {
        if (!this.selectedItem.days) {
          this.selectedItem.days = [];
        }
        this.selectedItem.days.push({
          title: "",
          description: "",
        });
      } else {
        this.newItem.days.push({
          title: "",
          description: "",
        });
      }
    },
  },
  mounted() {
    this.getItems();
    if (this.$loggedAdmin()) {
      this.token = this.$loggedAdminToken();
    } else if (this.$loggedClient()) {
      this.token = this.$loggedClientToken();
    } else {
      this.token = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.days {
  background: #f5f5f5;
  padding: 20px;
  .remove-day {
    text-align: right;
    button {
      background: #aa3535;
      color: #fff;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .add-day {
    text-align: right;
    button {
      background: #5f5f5f;
      color: #fff;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
