<template>
  <div class="Packages">
    <div class="admin-content">
      <adminSidebar />
      <div class="admin-body">
        <AdminLogged :brand="'Hotels'" />
        <div class="table">
          <br />
          <div class="table-title">
            <label for="Packages"></label>
            <button class="add-new" @click="addModal = true">
              <i class="fa fa-plus"></i> Add Hotel
            </button>
          </div>
          <table class="pads">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>location</th>
                <th>Contact</th>
                <!-- <th>Email</th>
                <th>Website</th> -->
                <th>Reservations</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <img
                    :src="$uploads(item.hotel_image)"
                    :alt="item.name"
                    class="thumbnail"
                  />
                </td>
                <td>{{ item.hotel_name }}</td>
                <td>{{ item.location.name }}</td>
                <td>{{ item.hotel_contact }}</td>
                <!-- <td>{{ item.hotel_email }}</td>
                <td>{{ item.hotel_website }}</td> -->
                <td>{{ item.orders }} <a-badge :count="item.neworders" /></td>
                <td>
                  <div class="table-buttons dropdown-wrap">
                    <a-dropdown>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item key="1">
                            <router-link
                              :to="'/admin/packages/orders/' + item.hotel_id"
                              class="button videos"
                              >Orders</router-link
                            >
                          </a-menu-item>
                          <a-menu-item key="2" @click="startRooms(item)">
                            Rooms
                          </a-menu-item>
                          <a-menu-item key="3" @click="startUpdate(item)">
                            Edit
                          </a-menu-item>
                          <a-menu-item
                            key="4"
                            @click="startDelete(item)"
                            class="delete"
                          >
                            Delete
                          </a-menu-item>
                        </a-menu>
                      </template>
                      <a-button>
                        Actions
                        <!-- <DownOutlined /> -->
                        <i class="fa fa-angle-down"></i>
                      </a-button>
                    </a-dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="items.length == 0">
              <tr>
                <td colspan="7" height="100" class="no-data">
                  No Hotels Found
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination" v-if="items.length > 0">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
        <Model v-if="addModal == true" :size="'lg'">
          <div class="model-header">
            <h4>Add Hotel</h4>
            <button class="close" @click="addModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="Name">Location:</label>
                  <a-select v-model:value="newItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in attractions"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="Title">Hotel Name:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="hotel name"
                    v-model:value="newItem.hotel_name"
                  />
                </div>
                <div class="form-group">
                  <label for="Name">Featured:</label>
                  <a-select v-model:value="newItem.featured">
                    <a-select-option :value="false"> No </a-select-option>
                    <a-select-option :value="true"> Yes </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="Name">Phone:</label>
                  <a-input
                    v-model:value="newItem.hotel_contact"
                    placeholder="Phone"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Email:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Email"
                    v-model:value="newItem.hotel_email"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Website:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Website"
                    v-model:value="newItem.hotel_website"
                  />
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="max_people">Address:</label>
                  <a-input
                    type="text"
                    name="max_people"
                    id=""
                    placeholder="Address"
                    v-model:value="newItem.hotel_address"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Stars:</label>
                  <a-select
                    v-model:value="newItem.hotel_stars"
                    style="width: 100%"
                    placeholder="Stars"
                  >
                    <a-select-option :value="1">1</a-select-option>
                    <a-select-option :value="2">2</a-select-option>
                    <a-select-option :value="3">3</a-select-option>
                    <a-select-option :value="4">4</a-select-option>
                    <a-select-option :value="5">5</a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="">Min Price(USD):</label>
                  <a-input-group compact>
                    <a-input
                      v-model:value="newItem.price"
                      placeholder="Price"
                    ></a-input>
                  </a-input-group>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="" class="mt-2">Facilities</label
                  ><a-select
                    v-model:value="newItem.hotel_facilities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Facilities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
                <div class="form-group">
                  <label for="" class="mt-2">Nearby Facilities</label>
                  <a-select
                    v-model:value="newItem.hotel_nearby_facilities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Nearby Facilities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="email address">Description</label
                  ><quill-editor
                    v-model:content="newItem.hotel_description"
                    theme="snow"
                    contentType="html"
                  ></quill-editor>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        newItem.hotel_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.hotel_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>

                <div class="form-group">
                  <label for="email address">Other Images</label>
                  <br />
                  <o-upload
                    :multiple="true"
                    @imagesUploaded="
                      (data) => {
                        newItem.hotel_images = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="newItem.hotel_images"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" type="submit" @click.prevent="addItems()">
              Submit
            </button>
            <button class="close" @click="addModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateModal == true" :size="'lg'">
          <div class="model-header">
            <h4>Edit Hotel</h4>
            <button class="close" @click="updateModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="Name">Location:</label>
                  <a-select v-model:value="selectedItem.attraction_id">
                    <a-select-option value="" disabled selected>
                      -- Choose --
                    </a-select-option>
                    <a-select-option
                      :value="attraction.id"
                      v-for="attraction in attractions"
                      :key="attraction.id"
                    >
                      {{ attraction.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="Title">Hotel Name:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="hotel name"
                    v-model:value="selectedItem.hotel_name"
                  />
                </div>
                <div class="form-group">
                  <label for="Name">Featured:</label>
                  <a-select v-model:value="selectedItem.featured">
                    <a-select-option :value="0"> No </a-select-option>
                    <a-select-option :value="1"> Yes </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="Name">Phone:</label>
                  <a-input
                    v-model:value="selectedItem.hotel_contact"
                    placeholder="Phone"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Email:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Email"
                    v-model:value="selectedItem.hotel_email"
                  />
                </div>
                <div class="form-group">
                  <label for="Duration">Website:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Website"
                    v-model:value="selectedItem.hotel_website"
                  />
                </div>
              </div>
              <div class="input-group group-3">
                <div class="form-group">
                  <label for="max_people">Address:</label>
                  <a-input
                    type="text"
                    name="max_people"
                    id=""
                    placeholder="Address"
                    v-model:value="selectedItem.hotel_address"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Stars:</label>
                  <a-select
                    v-model:value="selectedItem.hotel_stars"
                    style="width: 100%"
                    placeholder="Stars"
                  >
                    <a-select-option :value="1">1</a-select-option>
                    <a-select-option :value="2">2</a-select-option>
                    <a-select-option :value="3">3</a-select-option>
                    <a-select-option :value="4">4</a-select-option>
                    <a-select-option :value="5">5</a-select-option>
                  </a-select>
                </div>
                <div class="form-group">
                  <label for="">Min Price(USD):</label>
                  <a-input-group compact>
                    <a-input
                      v-model:value="selectedItem.price"
                      placeholder="Price"
                    ></a-input>
                  </a-input-group>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="" class="mt-2">Facilities</label
                  ><a-select
                    v-model:value="selectedItem.hotel_facilities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Facilities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
                <div class="form-group">
                  <label for="" class="mt-2">Nearby Facilities</label>
                  <a-select
                    v-model:value="selectedItem.hotel_nearby_facilities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Nearby Facilities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="email address">Description</label
                  ><QuillEditor
                    v-model:content="selectedItem.hotel_description"
                    theme="snow"
                    contentType="html"
                  ></QuillEditor>
                </div>
              </div>
              <div class="input-group group-2">
                <div class="form-group">
                  <label for="email address">Image</label>
                  <br />
                  <o-upload
                    :multiple="false"
                    @imagesUploaded="
                      (data) => {
                        selectedItem.hotel_image = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="selectedItem.hotel_image"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>

                <div class="form-group">
                  <label for="email address">Other Images</label>
                  <br />
                  <o-upload
                    :multiple="true"
                    @imagesUploaded="
                      (data) => {
                        selectedItem.hotel_images = data;
                      }
                    "
                    @imagesUploadedFailed="
                      (data) => {
                        console.log('failed');
                      }
                    "
                    :value="selectedItem.hotel_images"
                    @startLoader="() => $startLoader()"
                    @stopLoader="() => $stopLoader()"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateItems()">
              Update
            </button>
            <button class="close" @click="updateModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="deleteModal == true">
          <div class="model-header">
            <h4>Delete Hotel</h4>
            <button class="close" @click="deleteModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete this Hotel?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteItems()">Yes</button>
            <button class="close" @click="deleteModal = false">Cancel</button>
          </div>
        </Model>

        <leftView
          :modelTitle="selectedItem.hotel_name + ' Rooms'"
          @closeModel="showRooms = false"
          v-if="showRooms == true"
        >
          <div class="show-room">
            <div class="form">
              <div class="form-header">
                <h4><i class="fa fa-bed"></i> Rooms</h4>
                <button class="add-new" @click="addRoomModal = true">
                  <i class="fa fa-plus"></i> Add Room
                </button>
              </div>
              <div class="form-body">
                <table class="pads">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(room, index) in rooms" :key="room.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ room.room_name }}</td>
                      <td>{{ room.room_type }}</td>
                      <td>{{ room.room_price }}</td>
                      <td>
                        <div class="table-buttons dropdown-wrap">
                          <a-dropdown>
                            <template #overlay>
                              <a-menu>
                                <a-menu-item
                                  key="1"
                                  @click="startUpdateRoom(room)"
                                >
                                  Edit
                                </a-menu-item>
                                <a-menu-item
                                  key="2"
                                  @click="startDeleteRoom(room)"
                                  class="delete"
                                >
                                  Delete
                                </a-menu-item>
                              </a-menu>
                            </template>
                            <a-button>
                              Actions
                              <i class="fa fa-angle-down"></i>
                            </a-button>
                          </a-dropdown>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="rooms.length == 0">
                    <tr>
                      <td colspan="6" height="100" class="no-data">
                        No Rooms Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </leftView>

        <Model v-if="addRoomModal == true">
          <div class="model-header">
            <h4>Add Room</h4>
            <button class="close" @click="addRoomModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="Name">Room Name:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Room name"
                    v-model:value="newRoom.room_name"
                  />
                </div>
                <div class="form-group">
                  <label for="Title">Room Type:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Room type"
                    v-model:value="newRoom.room_type"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Price:</label>
                  <a-input-group compact>
                    <a-input
                      v-model:value="newRoom.room_price"
                      placeholder="Price"
                    ></a-input>
                  </a-input-group>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="max_people">Amenities:</label>
                  <a-select
                    v-model:value="newRoom.amenities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Amenities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" type="submit" @click.prevent="addRoom()">
              Submit
            </button>
            <button class="close" @click="addRoomModal = false">Close</button>
          </div>
        </Model>
        <Model v-if="updateRoomModal == true">
          <div class="model-header">
            <h4>Edit Room</h4>
            <button class="close" @click="updateRoomModal = false">X</button>
          </div>
          <div class="model-body">
            <form @submit.prevent="">
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="Name">Room Name:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Room name"
                    v-model:value="selectedRoom.room_name"
                  />
                </div>
                <div class="form-group">
                  <label for="Title">Room Type:</label>
                  <a-input
                    type="text"
                    id=""
                    placeholder="Room type"
                    v-model:value="selectedRoom.room_type"
                  />
                </div>
                <div class="form-group">
                  <label for="max_people">Price:</label>
                  <a-input-group compact>
                    <a-input
                      v-model:value="selectedRoom.room_price"
                      placeholder="Price"
                    ></a-input>
                  </a-input-group>
                </div>
              </div>
              <div class="input-group group-1">
                <div class="form-group">
                  <label for="max_people">Amenities:</label>
                  <a-select
                    v-model:value="selectedRoom.amenities"
                    mode="tags"
                    style="width: 100%"
                    placeholder="Amenities"
                    :token-separators="[',']"
                    @change="handleChange"
                  ></a-select>
                </div>
              </div>
            </form>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="updateRoom()">Update</button>
            <button class="close" @click="updateRoomModal = false">
              Close
            </button>
          </div>
        </Model>
        <Model v-if="deleteRoomModal == true">
          <div class="model-header">
            <h4>Delete Room</h4>
            <button class="close" @click="deleteRoomModal = false">X</button>
          </div>
          <div class="model-body">
            <h4 class="delete-label">
              Are you sure you want to delete this Room?
            </h4>
          </div>
          <div class="model-footer">
            <button class="submit" @click.prevent="deleteRoom()">Yes</button>
            <button class="close" @click="deleteRoomModal = false">
              Cancel
            </button>
          </div>
        </Model>
      </div>
    </div>
  </div>
</template>

<script>
import Model from "./components/Model.vue";
export default {
  name: "Packages",
  components: {
    Model,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      color: "#015b0a",
      addModal: false,
      updateModal: false,
      deleteModal: false,
      newItem: {
        attraction_id: "",
        hotel_name: "",
        hotel_contact: "",
        hotel_email: "",
        hotel_website: "",
        hotel_address: "",
        hotel_stars: 1,
        price: 0,
        hotel_facilities: [],
        hotel_nearby_facilities: [],
        description: "",
        image: "",
        images: [],
        featured: false,
      },
      selectedItem: {},
      items: [],
      attractions: [],
      token: null,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      showRooms: false,
      rooms: [],
      newRoom: {
        hotel_id: "",
        room_name: "",
        room_type: "",
        room_price: 0,
        amenities: [],
      },
      selectedRoom: null,
      addRoomModal: false,
      updateRoomModal: false,
      deleteRoomModal: false,
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_HOTELS", {
          token: this.$loggedAdminToken(),
          paginate: this.itemsPerpage,
          page: this.currentPage,
        })
        .then((response) => {
          this.items = response.data.data;
          this.$stopLoader();
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
    },
    addItems() {
      this.$startLoader();
      if (
        this.newItem.attraction_id != "" &&
        this.newItem.hotel_name != "" &&
        this.newItem.hotel_image
      ) {
        this.$store
          .dispatch("ADD_HOTEL", this.newItem)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.addModal = false;
              this.newItem.hotel_name = "";
              this.newItem.hotel_contact = "";
              this.newItem.hotel_email = "";
              this.newItem.hotel_website = "";
              this.newItem.hotel_address = "";
              this.newItem.hotel_stars = 1;
              this.newItem.price = 0;
              this.getItems();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            this.$stopLoader();
          });
      } else {
        this.$stopLoader();
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startUpdate(item) {
      this.selectedItem = item;
      this.updateModal = true;
    },
    updateItems() {
      this.$startLoader();
      if (
        this.selectedItem.attraction_id != "" &&
        this.selectedItem.hotel_name != "" &&
        this.selectedItem.hotel_image
      ) {
        this.$store
          .dispatch("UPDATE_HOTEL", this.selectedItem)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.updateModal = false;
              this.selectedItem = "";
              this.getItems();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            this.$stopLoader();
          });
      } else {
        this.$stopLoader();
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startDelete(item) {
      this.selectedItem = item;
      this.deleteModal = true;
    },
    deleteItems() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_HOTEL", this.selectedItem.hotel_id)
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteModal = false;
            this.selectedItem = "";
            this.getItems();
            this.$stopLoader();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
            this.$stopLoader();
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
    },
    getAttractions() {
      this.$store
        .dispatch("GET_ATTRACTION", {
          token: this.$loggedAdminToken(),
          paginate: 1000,
          page: 1,
        })
        .then((response) => {
          this.attractions = response.data.data;
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
    },
    getRooms() {
      this.$store
        .dispatch("GET_ROOMS_BY_HOTEL_ID", {
          token: this.$loggedAdminToken(),
          paginate: 1000,
          page: 1,
          hotel_id: this.selectedItem.hotel_id,
        })
        .then((response) => {
          this.rooms = response.data.data;
        })
        .catch((error) => {
          console.error({
            error,
          });
        });
    },
    startRooms(item) {
      this.selectedItem = item;
      this.getRooms();
      this.showRooms = true;
    },
    addRoom() {
      this.$startLoader();
      if (
        this.newRoom.room_name != "" &&
        this.newRoom.room_type != "" &&
        this.newRoom.room_price != 0
      ) {
        this.newRoom.hotel_id = this.selectedItem.hotel_id;
        this.$store
          .dispatch("ADD_ROOM", this.newRoom)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.addRoomModal = false;
              this.newRoom.room_name = "";
              this.newRoom.room_type = "";
              this.newRoom.room_price = 0;
              this.getRooms();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            this.$stopLoader();
          });
      } else {
        this.$stopLoader();
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startUpdateRoom(room) {
      this.selectedRoom = room;
      this.updateRoomModal = true;
    },
    updateRoom() {
      this.$startLoader();
      if (
        this.selectedRoom.room_name != "" &&
        this.selectedRoom.room_type != "" &&
        this.selectedRoom.room_price != 0
      ) {
        this.$store
          .dispatch("UPDATE_ROOM", this.selectedRoom)
          .then((response) => {
            if (response.data.status == "ok") {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "success",
              });
              this.updateRoomModal = false;
              this.selectedRoom = "";
              this.getRooms();
              this.$stopLoader();
            } else {
              this.$notify({
                group: "status",
                title: "Important message",
                text: response.data.message,
                type: "error",
              });
              this.$stopLoader();
            }
          })
          .catch((error) => {
            console.error({
              error,
            });
            this.$stopLoader();
          });
      } else {
        this.$stopLoader();
        this.$notify({
          group: "status",
          title: "Important message",
          text: "All Fields are required",
          type: "error",
        });
      }
    },
    startDeleteRoom(room) {
      this.selectedRoom = room;
      this.deleteRoomModal = true;
    },
    deleteRoom() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ROOM", { room_id: this.selectedRoom.room_id })
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "success",
            });
            this.deleteRoomModal = false;
            this.selectedRoom = "";
            this.getRooms();
            this.$stopLoader();
          } else {
            this.$notify({
              group: "status",
              title: "Important message",
              text: response.data.message,
              type: "error",
            });
            this.$stopLoader();
          }
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getAttractions();
    this.getItems();
    if (this.$loggedAdmin()) {
      this.token = this.$loggedAdminToken();
    } else if (this.$loggedClient()) {
      this.token = this.$loggedClientToken();
    } else {
      this.token = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.days {
  background: #f5f5f5;
  padding: 20px;
  .remove-day {
    text-align: right;
    button {
      background: #aa3535;
      color: #fff;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .add-day {
    text-align: right;
    button {
      background: #5f5f5f;
      color: #fff;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      display: block;
      cursor: pointer;
    }
  }
}
.dropdown-wrap :deep(button) {
  margin-right: 0px;
  margin-bottom: 0px;
  background: $primary_color;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: $primary_color;
    color: #fff;
  }
  i {
    margin-left: 5px;
    font-size: 10px;
  }
}
.show-room {
  .form {
    .form-header {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      h4 {
        margin: 0;
      }
      .add-new {
        background: $primary_color;
        color: #fff;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
    }
    .form-body {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          background: $primary_color;
          color: #fff;
          th {
            padding: 10px;
            text-align: left;
          }
        }
        tbody {
          tr {
            td {
              padding: 10px;
              border-bottom: 1px solid #f5f5f5;
            }
          }
        }
      }
    }
  }
}
</style>
