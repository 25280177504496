<template>
  <div class="home">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Stays
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Stays</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container pac">
        <div class="left-section">
          <div class="packages" v-if="isLoading == false">
            <div
              class="packages-body"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <router-link
                :to="{
                  name: 'ViewStay',
                  params: {
                    id: item.stay_id,
                    name: $urlCompatible(item.stay_name),
                  },
                }"
                class="package-item"
                v-for="item in items"
                :key="item.stay_id"
              >
                <div class="content">
                  <div class="img-section jc-center">
                    <img
                      :src="$uploads(item.stay_image)"
                      :alt="item.location.name"
                      srcset=""
                    />
                    <div class="specs">
                      <div class="region">
                        <i class="fas fa-map-marker-alt"></i>
                        <label for="Tourism Region">{{
                          item.location.name
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="text-section">
                    <label :for="item.location.name" class="package-title">{{
                      item.stay_name
                    }}</label>
                  </div>
                  <hr />
                  <div class="content-footer">
                    <div class="money">
                      <label for="Amount" v-if="item.price">
                        <span>Price Starts From </span>
                        <p>{{ $money(item.price) }}</p></label
                      >
                      <label for="Amount" v-else>-</label>
                    </div>
                    <div class="book">
                      <router-link
                        :to="{
                          name: 'ViewStay',
                          params: {
                            id: item.stay_id,
                            name: $urlCompatible(item.stay_name),
                          },
                        }"
                        class="book-btn"
                        >Reserve
                        <i class="fa-solid fa-arrow-right"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="pagination">
              <button
                :class="{ disabled: currentPage === 1 }"
                @click="$previousPage()"
              >
                <label>Previous</label>
              </button>
              <p v-if="currentPage > 3">...</p>
              <button
                class="numbers"
                v-for="number in pagesNumber"
                :key="number"
              >
                <label
                  :class="{ active: currentPage === number }"
                  for=""
                  v-if="
                    (number == 1 && currentPage <= 2) ||
                    (currentPage + 2 >= pagesNumber &&
                      number >= pagesNumber - 2) ||
                    (number >= currentPage - 2 && number <= currentPage + 2)
                  "
                  @click="$changePage(number)"
                >
                  {{ number }}
                </label>
              </button>
              <p v-if="currentPage <= pagesNumber - 3">...</p>
              <button
                :class="{ disabled: currentPage === pagesNumber.length }"
                @click="$nextPage()"
              >
                <label>Next</label>
              </button>
            </div>
          </div>
          <div v-else>
            <a-skeleton active />
            <a-skeleton active />
            <a-skeleton active />
          </div>
        </div>
        <div class="right-section">
          <div class="filter-packages">
            <div class="filter-header">
              <label for="Filter">Filter Stays</label>
            </div>
            <div class="filter-body">
              <div class="filter-item">
                <label for="">Title / Name</label>
                <input
                  type="text"
                  placeholder="Search by title, location, activity"
                  @keyup="getItems"
                  v-model="searchData.searchQuery"
                />
              </div>
              <div class="filter-item">
                <label for="Country">Destination</label>
                <a-select
                  v-model:value="searchData.attraction_id"
                  style="width: 100%"
                  placeholder="Select a destination"
                  @change="getItems"
                >
                  <a-select-option value="">All</a-select-option>
                  <a-select-option
                    v-for="item in destinations"
                    :key="item.id"
                    :value="item.id"
                    @change="getItems"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="filter-item">
                <label for="Price">Price</label>
                <div class="checkboxes">
                  <div class="checkbox">
                    <a-checkbox @change="changePrice(0, 500)"
                      >Less than $500</a-checkbox
                    >
                  </div>
                  <div class="checkbox">
                    <a-checkbox @change="changePrice(500, 1000)"
                      >$500 - $1000</a-checkbox
                    >
                  </div>
                  <div class="checkbox">
                    <a-checkbox @change="changePrice(1000, 2000)"
                      >$1000 - $2000</a-checkbox
                    >
                  </div>
                  <div class="checkbox">
                    <a-checkbox @change="changePrice(2000, 5000)"
                      >$2000 - $5000</a-checkbox
                    >
                  </div>
                  <div class="checkbox">
                    <a-checkbox @change="changePrice(5000, 10000)"
                      >$5000 - $10000</a-checkbox
                    >
                  </div>
                  <div class="checkbox">
                    <a-checkbox @change="changePrice('over 10000')"
                      >Over $10000</a-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      items: [],
      trips: [],
      partners: [],
      currentSlide: 0,
      activetab: 1,
      allItemsCount: 0,
      itemsPerpage: 12,
      pagesNumber: 1,
      currentPage: 1,
      destinations: [],
      searchData: {
        attraction_id: "",
        priceRanges: [],
        facilities: "",
        searchQuery: "",
      },
      isLoading: false,
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getItems() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_FILTERED_STAYS", {
          token: this.$loggedAdminToken(),
          paginate: this.itemsPerpage,
          page: this.currentPage,
          attraction_id: this.searchData.attraction_id,
          priceRanges: this.searchData.priceRanges,
          searchQuery: this.searchData.searchQuery,
        })
        .then((response) => {
          this.items = response.data.data;
          this.allItemsCount = response.data.total;
          this.pagesNumber = Math.ceil(this.allItemsCount / this.itemsPerpage);

          this.isLoading = false;
        })
        .catch((error) => {
          console.error({
            error,
          });
          this.isLoading = false;
        });
    },

    changePrice(min, max) {
      if (min == "over 10000") {
        let index = this.searchData.priceRanges.findIndex(
          (price) => price[0] == 10000
        );
        if (index > -1) {
          this.searchData.priceRanges.splice(index, 1);
        } else {
          this.searchData.priceRanges.push([10000, 1000000]);
        }
      } else {
        let index = this.searchData.priceRanges.findIndex(
          (price) => price[0] == min && price[1] == max
        );
        if (index > -1) {
          this.searchData.priceRanges.splice(index, 1);
        } else {
          this.searchData.priceRanges.push([min, max]);
        }
      }
      this.getItems();
    },
    getDestinations() {
      this.$store
        .dispatch("GET_ATTRACTION", {
          paginate: 1000,
          page: 1,
        })
        .then((response) => {
          this.destinations = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    let vm = this;
    this.getItems();
    this.getDestinations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .pac {
    display: grid;
    grid-template-columns: 70% 30%;
    .packages {
      padding-top: 1rem;
    }
    .right-section {
      padding-top: 3rem;
      padding-left: 2rem;
    }
  }
}
</style>
