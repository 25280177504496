


import {
  API_BASE_URL
} from "../variables";

const GET_ROOMS_URL = API_BASE_URL + "/admin/hotelRooms/getHotelRooms";
const ADD_ROOM_URL = API_BASE_URL + "/admin/hotelRooms/addHotelRoom";
const UPDATE_ROOM_URL = API_BASE_URL + "/admin/hotelRooms/updateHotelRoom";
const DELETE_ROOM_URL = API_BASE_URL + "/admin/hotelRooms/deleteHotelRoom";
const GET_ROOM_BY_ID_URL = API_BASE_URL + "/admin/hotelRooms/getHotelRoomById";
const GET_ROOMS_BY_HOTEL_ID_URL = API_BASE_URL + "/admin/hotelRooms/getHotelRoomsByHotelId";


import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  ["GET_ROOMS"]: (state, payload) => {
    return axios({
      url: GET_ROOMS_URL + `?page=${payload.page}`,
      method: "POST",
      data: payload,
    });
  },
  ["ADD_ROOM"]: (state, payload) => {
    return axios({
      url: ADD_ROOM_URL,
      method: "POST",
      data: payload,
    });
  },
  ["UPDATE_ROOM"]: (state, payload) => {
    return axios({
      url: UPDATE_ROOM_URL,
      method: "POST",
      data: payload,
    });
  },
  ["DELETE_ROOM"]: (state, payload) => {
    return axios({
      url: DELETE_ROOM_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_ROOM_BY_ID"]: (state, payload) => {
    return axios({
      url: GET_ROOM_BY_ID_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_ROOMS_BY_HOTEL_ID"]: (state, payload) => {
    return axios({
      url: GET_ROOMS_BY_HOTEL_ID_URL,
      method: "POST",
      data: payload,
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
