<template>
  <div class="leftView" :class="modelOpened ? 'active' : ''">
    <div
      class="dialog"
      :class="dialogActive ? 'active' : ''"
      v-click-out-side="customMethod2"
    >
      <div class="dialog-header">
        <h1>{{ modelTitle }}</h1>
        <button @click="closeModel">&times;</button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
  
  <script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  name: "leftView",
  props: {
    modelTitle: {
      type: String,
      default: "View",
    },
  },
  data() {
    return {
      dialogActive: false,
      clickCount: 0,
      modelOpened: true,
    };
  },
  directives: {
    clickOutSide,
  },
  methods: {
    closeModel() {
      this.dialogActive = false;
      this.modelOpened = false;
      setTimeout(() => {
        document.body.style.overflowY = "auto";
        this.$emit("closeModel");
      }, 500);
    },
    customMethod2() {
      this.clickCount++;
      if (this.clickCount > 2) {
        // this.closeModel();
      }
    },
  },
  mounted() {
    // setTimeout
    setTimeout(() => {
      this.dialogActive = true;
      this.modelOpened = true;
    }, 10);
    document.body.style.overflow = "hidden";
  },
};
</script>
  
  <style lang="scss" scoped>
@import "@/scss/main.scss";
.leftView {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  transition: all 0.5s ease-in-out;
  z-index: 5;

  &.active {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dialog {
    width: 600px;
    height: 100%;
    background-color: #eee;
    position: relative;
    right: -60%;
    top: 0;
    transition: all 0.5s ease-in-out;
    border-radius: 20px 0px 0px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    &.active {
      right: 0;
    }
    .dialog-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      border-bottom: 1px #e5e5e5 solid;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background-color: transparent;
        border: none;
        font-size: 30px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .dialog-body {
      width: 100%;
      height: calc(100% - 60px);
      padding: 1rem 20px;
      overflow-y: auto;
      .worker-item {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        padding: 0 10px;
        &:hover {
          background: #eee;
        }
        .top-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem;
          border-bottom: 1px solid #ccc;
          .image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            border: 3px solid #ccc;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              background: #f2f2f2;
            }
          }
          .info {
            width: calc(100% - 100px);
            .name {
              h4 {
                font-size: 1.5rem;
                font-weight: 500;
                color: $primary_color;
              }
              label {
                font-size: 1.25rem;
                font-weight: 500;
                color: #000;
              }
              p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
            }
          }
        }
        .bottom {
          display: flex;
          .middle-section {
            display: flex;
            flex-direction: column;
            padding: 1rem 1rem;
            width: 300px;
            border-right: 1px solid #ccc;
            row-gap: 1rem;
            .availability {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 0.9rem;
                font-weight: 400;
                i {
                  color: orange;
                  &.active {
                    color: #58b658;
                  }
                }
              }
            }
            .reviews {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
            .sector {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
            .category {
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              p {
                font-size: 1.1rem;
                font-weight: 600;
                color: black;
              }
            }
          }
          .bottom-section {
            width: calc(100% - 300px);
            padding: 1rem;
            .bio {
              p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
                &.desc {
                  font-size: 14px;
                  font-weight: 400;
                  margin-top: 5px;
                  color: #494949;
                  text-align: left;
                  span {
                    font-weight: 600;
                  }
                }
              }
            }
            .skills {
              margin-top: 15px;
              label {
                font-size: 0.9rem;
                font-weight: 400;
                color: #949494;
              }
              .skills-list {
                display: flex;
                flex-wrap: wrap;
                column-gap: 0.5rem;
                row-gap: 0.5rem;
                span {
                  padding: 3px 10px;
                  border-radius: 50px;
                  background: #d8d8d8;
                  font-size: 0.9rem;
                  font-weight: 400;
                  color: #303030;
                }
              }
            }
            .title {
              label {
                font-size: 1.8rem;
                font-weight: 500;
                color: #181818;
              }
            }
            .reviews-section {
              width: 100%;
              padding: 1rem;
              border-top: 1px solid #ccc;
              margin-top: 1rem;
              .all-reviews {
                width: 100%;
                .title-label {
                  font-size: 1.8rem;
                  font-weight: 500;
                  color: #181818;
                }
                .reviews-list {
                  width: 100%;
                  .review-item {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem;
                    border-bottom: 1px solid #ccc;
                    .top {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 10px;
                      .info {
                        width: calc(100% - 50px);
                        .name {
                          h4 {
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: $primary_color;
                          }
                          label {
                            font-size: 1.25rem;
                            font-weight: 500;
                            color: #000;
                          }
                          p {
                            font-size: 0.9rem;
                            font-weight: 400;
                            color: #949494;
                          }
                        }
                      }
                    }
                    .bottom {
                      p {
                        font-size: 0.85rem;
                        font-weight: 400;
                        color: #161616;
                        font-style: italic;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>