import { createStore } from 'vuex';
import { BASE_URL, UPLOAD_BASE_URL } from "./variables";
import Upload from "./modules/upload.js";
import User from "./modules/users.js";
import Attractions from "./modules/attraction.js";
import Package from "./modules/package.js";
import Trip from "./modules/trip.js";
import Gallery from "./modules/gallery.js";
import partners from "./modules/partners";
import slides from "./modules/slides";
import files from "./modules/files";
import blogs from "./modules/blogs";
import hotels from "./modules/hotels";
import stays from "./modules/stays";
import HotelRooms from "./modules/HotelRooms";
import StayUnits from "./modules/StayUnits";


export default createStore({
  state: {
    sidebar: true,
    access_token: null,
    writter_access_token: localStorage.getItem('writter_access_token'),
    BASE_URL: BASE_URL,
    UPLOAD_BASE_URL: UPLOAD_BASE_URL,
    main_loader: false,
    sidebarShow: "responsive",
    sidebarMinimize: false,
    isLoading: false,
    apiUploadUrl: UPLOAD_BASE_URL + "files/uploads/",
  },
  mutations: {
    ["SET_TOKEN"]: (state, payload) => {
      state.access_token = payload;
    },
    ["START_LOADER"]: (state) => {
      state.main_loader = true;
    },
    ["STOP_LOADER"]: (state) => {
      state.main_loader = false;
    },
    // toggleSidebarDesktop(state) {
    //   const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    //   state.sidebarShow = sidebarOpened ? false : "responsive";
    // },
    // toggleSidebarMobile(state) {
    //   const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    //   state.sidebarShow = sidebarClosed ? true : "responsive";
    // },
    // set(state, [variable, value]) {
    //   state[variable] = value;
    // },
  },
  getters: {
    getUserAccessToken(state) {
      return state.access_token;
    },
  },
  actions: {},
  modules: {
    Upload,
    User,
    Attractions,
    Package,
    Trip,
    Gallery,
    partners,
    slides,
    files,
    blogs,
    hotels,
    stays,
    HotelRooms,
    StayUnits,
  },
});
