<template>
  <div class="Blog">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Blog
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Blog</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="news-section-grid">
          <div class="news-header">
            <h2>Our Latest News</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
          <div class="news-body">
            <div class="news-item" v-for="item in items" :key="item.id">
              <div class="news-img">
                <img :src="$img(item.image)" :alt="item.title" />
                <div class="date">
                  <span>{{ $day(item.date) }}</span>
                  <p>{{ $mon(item.date) }}</p>
                </div>
              </div>
              <div class="news-content">
                <div class="writer">
                  <span>By {{ item.writer }}</span>
                  <div class="dot-separator"></div>
                  <span>{{ item.category }}</span>
                </div>
                <h3>{{ item.title }}</h3>
                <div class="news-footer">
                  <div class="read-more">
                    <router-link :to="'/blog/' + item.id"
                      >Read More
                      <i class="fa fa-arrow-right"></i>
                    </router-link>
                  </div>
                  <div class="time">{{ item.minutes }} Min Read</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Blog",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      items: [
        {
          id: 1,
          title:
            "Revolutionising the travel industry, one partnership at a time",
          image: "rwanda2.jpg",
          description:
            "It is a long established fact that a reader will be distracted by the readable content of. The point of using Lorem Ipsum is that it has a more It is a long established fact that a reader will be distracted by the readable long established fact that a reader will be distracted content of a page when looking at its layout.",
          date: "2022-12-12",
          writer: "John Doe",
          category: "Travel",
          minutes: "5",
        },
        {
          id: 2,
          title:
            "It is a long established fact that a reader will be distracted.",
          image: "zanzibar.jpg",
          description:
            "It is a long established fact that a reader will be distracted by the readable content of. The point of using Lorem Ipsum is that it has a more It is a long established fact that a reader will be distracted by the readable long established fact that a reader will be distracted content of a page when looking at its layout.",
          date: "2022-12-12",
          writer: "Jane Doe",
          category: "Tourism",
          minutes: "5",
        },
        {
          id: 3,
          title: "There are many variations of passages of sum available.",
          image: "kenya.jpg",
          description:
            "It is a long established fact that a reader will be distracted by the readable content of. The point of using Lorem Ipsum is that it has a more It is a long established fact that a reader will be distracted by the readable long established fact that a reader will be distracted content of a page when looking at its layout.",
          date: "2022-12-12",
          writer: "John Doe",
          category: "Adventure",
          minutes: "5",
        },
        {
          id: 4,
          title: "The standard chunk of Lorem Ipsum used since the 1500s.",
          image: "uganda.jpg",
          description:
            "It is a long established fact that a reader will be distracted by the readable content of. The point of using Lorem Ipsum is that it has a more It is a long established fact that a reader will be distracted by the readable long established fact that a reader will be distracted content of a page when looking at its layout.",
          date: "2022-12-12",
          writer: "Jane Doe",
          category: "Travel",
          minutes: "5",
        },
      ],
    };
  },
  methods: {
    onCancel() {
      console.log();
    },
    getItems() {
      // this.$startLoader();
      // this.$store.dispatch("GET_SITE_PACKAGES").then((response) => {
      //   this.items = response.data["packages"];
      //   this.items.forEach((item) => {
      //     item.price = JSON.parse(item.price);
      //   });
      //   this.itemsToShow = [...this.items];
      //   this.trips = response.data["trips"];
      //   this.trips.forEach((item) => {
      //     item.price = JSON.parse(item.price);
      //   });
      //   this.$stopLoader();
      // });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
.Blog {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .news-section-grid {
    display: block;
    grid-gap: 20px;
    margin-bottom: 3rem;
    .news-header {
      margin: 3rem 0;
      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
      }
      p {
        font-size: 1.2rem;
        color: #000;
        margin-bottom: 20px;
      }
    }
    .news-body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      .news-item {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        .news-img {
          position: relative;
          img {
            width: 100%;
            height: 250px;
            object-fit: cover;
          }
          .date {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 60px;
            gap: 15px;
            padding-top: 15px;
            span {
              font-size: 1rem;
              color: #fff;
              font-weight: 700;
              line-height: 0;
            }
            p {
              font-size: 0.6rem;
              font-weight: 400;
              color: #fff;
              line-height: 0;
            }
          }
          &:hover {
            .date {
              background-color: $primary_color;
              transition: all 0.5s ease;
            }
            img {
              transform: scale(1.1);
              transition: all 0.5s ease;
            }
          }
        }
        .news-content {
          padding: 20px;
          .writer {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            span {
              font-size: 0.8rem;
              color: #777;
              line-height: 0;
              font-weight: 600;
            }
            .dot-separator {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $primary_color;
              margin: 0 10px;
            }
          }
          h3 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
            margin-bottom: 30px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p {
            font-size: 1rem;
            color: #000;
            margin-bottom: 10px;
          }
          .news-footer {
            display: flex;
            justify-content: space-between;
            .time {
              font-size: 0.8rem;
              color: #777;
            }
            .read-more {
              a {
                font-size: 1rem;
                color: $primary_color;
                text-decoration: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                &:hover {
                  color: $primary_color;
                }
                i {
                  border: 1px solid $primary_color;
                  padding: 5px;
                  border-radius: 50%;
                  height: 20px;
                  width: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 5px;
                  font-size: 0.7rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
