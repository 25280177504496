import {
  GET_BLOG,
  ADD_BLOG,
  UPDATE_BLOG,
  DELETE_BLOG,
  GET_SINGLE_BLOG,
} from "../actions/blogs";

import {
  GET_BLOG_URL,
  ADD_BLOG_URL,
  UPDATE_BLOG_URL,
  DELETE_BLOG_URL,
  GET_SINGLE_BLOG_URL,
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  [GET_BLOG]: (state, payload) => {
    return axios({
      url: GET_BLOG_URL,
      method: "POST",
      data: payload
    });
  },
  [ADD_BLOG]: (state, payload) => {
    return axios({
      url: ADD_BLOG_URL,
      method: "POST",
      data: payload,
    });
  },
  [UPDATE_BLOG]: (state, payload) => {
    return axios({
      url: UPDATE_BLOG_URL,
      method: "POST",
      data: payload,
    });
  },
  [DELETE_BLOG]: (state, payload) => {
    return axios({
      url: DELETE_BLOG_URL,
      method: "POST",
      data: payload,
    });
  },
  [GET_SINGLE_BLOG]: (state, payload) => {
    return axios({
      url: GET_SINGLE_BLOG_URL,
      method: "POST",
      data: payload,
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
