


import {
  API_BASE_URL
} from "../variables";

const GET_STAYS_URL = API_BASE_URL + "/admin/stays/getStays";
const ADD_STAY_URL = API_BASE_URL + "/admin/stays/addStay";
const UPDATE_STAY_URL = API_BASE_URL + "/admin/stays/updateStay";
const DELETE_STAY_URL = API_BASE_URL + "/admin/stays/deleteStay";
const GET_STAY_BY_ID_URL = API_BASE_URL + "/admin/stays/getStayById";
const GET_FILTERED_STAYS_URL = API_BASE_URL + "/admin/stays/getFilteredStays";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  ["GET_STAYS"]: (state, payload) => {
    return axios({
      url: GET_STAYS_URL + `?page=${payload.page}`,
      method: "POST",
      data: payload,
    });
  },
  ["ADD_STAY"]: (state, payload) => {
    return axios({
      url: ADD_STAY_URL,
      method: "POST",
      data: payload,
    });
  },
  ["UPDATE_STAY"]: (state, payload) => {
    return axios({
      url: UPDATE_STAY_URL,
      method: "POST",
      data: payload,
    });
  },
  ["DELETE_STAY"]: (state, payload) => {
    return axios({
      url: DELETE_STAY_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_STAY_BY_ID"]: (state, payload) => {
    return axios({
      url: GET_STAY_BY_ID_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_FILTERED_STAYS"]: (state, payload) => {
    return axios({
      url: GET_FILTERED_STAYS_URL,
      method: "POST",
      data: payload,
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
